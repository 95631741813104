import {
  FileOutlined,
  PieChartOutlined,
  UserOutlined,
  DesktopOutlined,
  TeamOutlined,
  CalendarOutlined,
  HomeOutlined,
} from '@ant-design/icons'
import { Breadcrumb, Layout, Menu, theme } from 'antd'
import { useState } from 'react'
import 'antd/dist/reset.css'
// import logo from './logo.svg'
import './App.css'

const { Header, Content, Footer, Sider } = Layout
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  }
}
const items = [
  getItem('Лента новостей', '/', <HomeOutlined />),
  getItem('События', '/events', <CalendarOutlined />),
  getItem('База знаний', '/docs', <FileOutlined />),
  getItem('Аудиоподкасты', '/audio', <PieChartOutlined />),
  getItem('Видеокурсы', '/video', <DesktopOutlined />),
  getItem('Мастера', '/masters', <UserOutlined />, [
    getItem('Альбина Белая', '/masters/0'),
    getItem('Антон Робуль', '/masters/1'),
    getItem('Вера Сарычева', '/masters/7'),
    getItem('Владислав', '/masters/4'),
    getItem('Ирина Тихонова', '/masters/3'),
    getItem('Лидия Готошия', '/masters/10'),
    getItem('Мария Зыза', '/masters/9'),
    getItem('Михаил Прусс', '/masters/8'),
    getItem('Ольга Шир', '/masters/11'),
    getItem('Светлана Куленкова', '/masters/5'),
    getItem('Татьяна Бровкина', '/masters/2'),
    getItem('Татьяна Колотова', '/masters/6'),
  ]),
  getItem('Курсы', '/groups', <TeamOutlined />, [
    getItem('Код уникальности', '/groups/1'),
    getItem('Мастер ИО', '/groups/2'),
    getItem('Стратегии гениального игрока', '/groups/3'),
    getItem('Мастер Майнды', '/groups/4'),
  ]),
]
const App = () => {
  const [collapsed, setCollapsed] = useState(false)
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            background: 'rgba(255, 255, 255, 0.2)',
            textAlign: 'center',
            lineHeight: '32px',
            color: '#fff',
          }}
        >
          Код уникальности
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              margin: '0 16px',
            }}
          >
            Мастерская исцеляющего общения
          </span>
        </Header>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>Мастера</Breadcrumb.Item>
            <Breadcrumb.Item>Антон Робуль</Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            Описание
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Мастерская ИО © Код уникальности
        </Footer>
      </Layout>
    </Layout>
  )
}
export default App
